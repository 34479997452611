// src/pages/DataEngineering.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from './Footer';
import dataEngineeringImg from  '../images/dataeng.jpeg'
import './Dataengineer.css'

const DataEngineering = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/service');
    };

    return (
        <>
            <Header />
            <div className="de-container">
                {/* Hero Section */}
                <section className="de-hero-section">
                    <div className="de-hero-content">
                        <h1 className="de-hero-title">Data Engineering</h1>
                        <p className="de-hero-description">
                            Unlock the power of data with our comprehensive data engineering services. We provide solutions to help you gather, process, and analyze data to drive actionable insights.
                        </p>
                        <button className="de-hero-button" onClick={goBack}>Back to Service</button>
                    </div>
                    <img src={dataEngineeringImg} alt="Data Engineering" className="de-hero-image" />
                </section>

                {/* Services Section */}
                <section className="de-services-section">
                    <h2 className="de-services-title">Our Expertise in Data Engineering</h2>
                    <div className="de-services-grid">
                        <div className="de-service-card">
                            <div className="de-service-icon">📊</div>
                            <h3>Data Integration</h3>
                            <p>Seamlessly integrate data from multiple sources into a unified platform for better insights.</p>
                        </div>
                        <div className="de-service-card">
                            <div className="de-service-icon">🛠️</div>
                            <h3>Data Transformation</h3>
                            <p>Transform raw data into meaningful information through advanced processing techniques.</p>
                        </div>
                        <div className="de-service-card">
                            <div className="de-service-icon">🔍</div>
                            <h3>Data Quality Management</h3>
                            <p>Ensure high-quality data with robust validation and cleansing processes.</p>
                        </div>
                    </div>
                </section>

                {/* Process Section */}
                <section className="de-process-section">
                    <h2 className="de-process-title">Our Proven Data Engineering Process</h2>
                    <div className="de-process-steps">
                        <div className="de-process-step">
                            <div className="de-step-number">1</div>
                            <h4>Consultation</h4>
                            <p>Understand your data needs and define a strategic plan for data engineering.</p>
                        </div>
                        <div className="de-process-step">
                            <div className="de-step-number">2</div>
                            <h4>Architecture Design</h4>
                            <p>Design a scalable data architecture that supports your business requirements.</p>
                        </div>
                        <div className="de-process-step">
                            <div className="de-step-number">3</div>
                            <h4>Implementation</h4>
                            <p>Develop and deploy data pipelines, ETL processes, and databases.</p>
                        </div>
                        <div className="de-process-step">
                            <div className="de-step-number">4</div>
                            <h4>Optimization</h4>
                            <p>Optimize data workflows and ensure high performance and reliability.</p>
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    );
};

export default DataEngineering;