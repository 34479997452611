import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from './Components/Header'
import Service from './Components/Service'
import Contact from './Components/Contact'
import Internship from './Components/Internship'
import ArtsDrawingForm from './Components/Arts'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import About from './Components/About'
import Cloudcomputing from './Components/Cloudcomputing'
import Mobileapp from './Components/Mobileapp'
import Kidsprograme from './Components/Kidsprograme'
import Dataengineer from './Components/Dataengineer'
import Webdevelopment from './Components/Webdevelopment'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="" element={<Header />} />
      <Route path="/Service" element={<Service />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/About" element={<About />} />
      <Route path="/Arts" element={<ArtsDrawingForm />} />
      <Route path="/Internship" element={<Internship />} />
      <Route path="/Cloudcomputing" element={<Cloudcomputing />} />
      <Route path="/Mobileapp" element={<Mobileapp />} />
      <Route path="/Kidsprograme" element={<Kidsprograme />} />
      <Route path="/Dataengineer" element={<Dataengineer />} />
      <Route path="/Webdevelopment" element={<Webdevelopment />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
