import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from './Footer';
import webDevImg from '../images/webdesign.png'; 
import slideimg1 from '../images/Reactimg.jpeg';
import slideimg2 from '../images/php.jpeg';
import slideimg3 from '../images/dotnet.jpeg';
import slideimg4 from '../images/angular.jpeg';
import slideimg5 from '../images/html.jpeg';
import slideimg6 from '../images/python.jpeg';
import slideimg7 from '../images/java.jpeg';
import slideimg8 from '../images/vue.jpeg';
import slideimg9 from '../images/boot.jpeg';
import slideimg10 from '../images/css.jpeg';
import slideimg11 from '../images/jss.jpeg';
import slideimg12 from '../images/c++.jpeg';
import './Webdevelopment.css'

const WebDevelopment = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/service');
    };

    return (
        <>
            <Header />
            <div className="wd-container">
                {/* Hero Section */}
                <section className="wd-hero-section">
                    <div className="wd-hero-content">
                        <h1 className="wd-hero-title">Web Development</h1>
                        <p className="wd-hero-description">
                            Transform your online presence with our cutting-edge web development services. From custom websites to dynamic web applications, we build solutions that drive results.
                        </p>
                        <button className="wd-hero-button" onClick={goBack}>Back to Service</button>
                    </div>
                    <img src={webDevImg} alt="Web Development" className="wd-hero-image" />
                </section>

                {/* Services Section */}
                <section className="wd-services-section">
                    <h2 className="wd-services-title">Our Web Development Services</h2>
                    <div className="wd-services-grid">
                        <div className="wd-service-card">
                            <div className="wd-service-icon">🌐</div>
                            <h3>Responsive Websites</h3>
                            <p>Design and develop websites that look great on all devices, ensuring a seamless user experience.</p>
                        </div>
                        <div className="wd-service-card">
                            <div className="wd-service-icon">🔧</div>
                            <h3>Custom Web Applications</h3>
                            <p>Build tailor-made web applications with advanced functionalities to meet your business needs.</p>
                        </div>
                        <div className="wd-service-card">
                            <div className="wd-service-icon">🚀</div>
                            <h3>Performance Optimization</h3>
                            <p>Optimize your website’s speed and performance to enhance user engagement and reduce bounce rates.</p>
                        </div>
                    </div>
                </section>

                {/* Process Section */}
                <section className="wd-process-section">
                    <h2 className="wd-process-title">Our Development Process</h2>
                    <div className="wd-process-steps">
                        <div className="wd-process-step">
                            <div className="wd-step-number">1</div>
                            <h4>Design</h4>
                            <p>Create wireframes and design mockups to visualize the user interface and experience.</p>
                        </div>
                        <div className="wd-process-step">
                            <div className="wd-step-number">2</div>
                            <h4>Development</h4>
                            <p>Develop the website using modern technologies and ensure all functionalities are implemented.</p>
                        </div>
                        <div className="wd-process-step">
                            <div className="wd-step-number">3</div>
                            <h4>Testing & Launch</h4>
                            <p>Test the website thoroughly, fix any issues, and launch it to the public.</p>
                        </div>
                    </div>
                </section>
                <div>
        <p className='headertext'>Frontend Languages</p>
      </div>
      <div className="slider">
        <div className="slider-track">
          <img src={slideimg1} alt="Loading" className="loading-img" />
          <img src={slideimg2} alt="Loading" className="loading-img" />
          <img src={slideimg3} alt="Loading" className="loading-img" />
          <img src={slideimg4} alt="Loading" className="loading-img" />
          <img src={slideimg5} alt="Loading" className="loading-img" />
          <img src={slideimg6} alt="Loading" className="loading-img" />
          <img src={slideimg7} alt="Loading" className="loading-img" />
          <img src={slideimg8} alt="Loading" className="loading-img" />
          <img src={slideimg9} alt="Loading" className="loading-img" />
          <img src={slideimg10} alt="Loading" className="loading-img" />
          <img src={slideimg11} alt="Loading" className="loading-img" />
          <img src={slideimg12} alt="Loading" className="loading-img" />
          {/* Duplicate the images for continuous sliding */}
          <img src={slideimg1} alt="Loading" className="loading-img" />
          <img src={slideimg2} alt="Loading" className="loading-img" />
          <img src={slideimg3} alt="Loading" className="loading-img" />
          <img src={slideimg4} alt="Loading" className="loading-img" />
          <img src={slideimg5} alt="Loading" className="loading-img" />
          <img src={slideimg6} alt="Loading" className="loading-img" />
          <img src={slideimg7} alt="Loading" className="loading-img" />
          <img src={slideimg8} alt="Loading" className="loading-img" />
          <img src={slideimg9} alt="Loading" className="loading-img" />
          <img src={slideimg10} alt="Loading" className="loading-img" />
          <img src={slideimg11} alt="Loading" className="loading-img" />
          <img src={slideimg12} alt="Loading" className="loading-img" />
          
        </div>
      </div>
    </div>
      <div style={{marginTop:'25px'}}>
      <Footer />
        </div>      
        </>
    );
};

export default WebDevelopment;