import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from './Footer';
import mobileAppImg from '../images/mobileapp.jpeg'; // Import the image
import './Mobileapp.css'

const MobileAppDevelopment = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/Service');
    };

    return (
        <>
            <Header />
            <div className="mobileapp-container">
                {/* Hero Section */}
                <div className="mobileapp-hero">
                    <div className="hero-content">
                        <h1 className="hero-title">Mobile App Development</h1>
                        <p className="hero-description">
                            Transform your business idea into a seamless mobile experience. We specialize in creating responsive, scalable, and engaging apps for iOS and Android platforms.
                        </p>
                        <button className="hero-button" onClick={goBack}>Back to Service</button>
                    </div>
                    <img src={mobileAppImg} alt="Mobile App Development" className="mobileapp-img" />
                </div>

                {/* Main Content */}
                <div className="mobileapp-content">
                    <h2 className="section-title">Our Mobile App Development Process</h2>

                    {/* Process Steps */}
                    <div className="process-step">
                        <div className="process-icon">
                            <i className="fas fa-lightbulb"></i>
                        </div>
                        <div>
                            <h3>1. Idea & Strategy</h3>
                            <p>We work closely with you to brainstorm and develop a strategy that aligns with your business goals and creates the most impact.</p>
                        </div>
                    </div>

                    <div className="process-step">
                        <div className="process-icon">
                            <i className="fas fa-pencil-ruler"></i>
                        </div>
                        <div>
                            <h3>2. UI/UX Design</h3>
                            <p>Our creative team designs user-friendly and engaging interfaces that ensure an excellent user experience, keeping aesthetics and functionality in mind.</p>
                        </div>
                    </div>

                    <div className="process-step">
                        <div className="process-icon">
                            <i className="fas fa-code"></i>
                        </div>
                        <div>
                            <h3>3. Development & Testing</h3>
                            <p>We develop apps using the latest technologies and thoroughly test them to ensure flawless performance across multiple devices.</p>
                        </div>
                    </div>

                    <div className="process-step">
                        <div className="process-icon">
                            <i className="fas fa-rocket"></i>
                        </div>
                        <div>
                            <h3>4. Launch & Support</h3>
                            <p>After a successful launch, we provide ongoing support and updates to ensure your app stays relevant and fully functional over time.</p>
                        </div>
                    </div>

                    {/* Additional Features Section */}
                    <div className="mobileapp-features">
                        <h2 className="section-title">Why Choose Us?</h2>
                        <div className="features-grid">
                            <div className="feature-box">
                                <i className="fas fa-mobile-alt"></i>
                                <h4>Cross-Platform Development</h4>
                                <p>We build apps for both iOS and Android using modern frameworks like React Native and Flutter.</p>
                            </div>
                            <div className="feature-box">
                                <i className="fas fa-shield-alt"></i>
                                <h4>Secure & Scalable</h4>
                                <p>Security is at the core of our development process, and we ensure that your app can scale with your growing business.</p>
                            </div>
                            <div className="feature-box">
                                <i className="fas fa-cogs"></i>
                                <h4>Continuous Integration</h4>
                                <p>Our continuous integration and deployment process ensures seamless updates and efficient app maintenance.</p>
                            </div>
                            <div className="feature-box">
                                <i className="fas fa-chart-line"></i>
                                <h4>Data-Driven Insights</h4>
                                <p>We help you track user behavior and make data-driven decisions to improve user engagement and retention.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default MobileAppDevelopment;