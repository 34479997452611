import React from "react";
import Header from './Components/Header'
import Home from './Components/Home'
import Footer from './Components/Footer'


function Main() {
  return (
    <><Home /></>
  );
}

export default Main;