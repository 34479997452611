import React from 'react'
import {
    CloudOutlined as CloudOutlinedIcon,
    DataUsageOutlined as DataUsageOutlinedIcon,
    WebOutlined as WebOutlinedIcon,
    PhoneAndroidOutlined as PhoneAndroidOutlinedIcon,
    BusinessCenterOutlined as BusinessCenterOutlinedIcon,
    WorkOutlineOutlined as WorkOutlineOutlinedIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import './Footer.css'
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Company Information */}
                <div className="footer-section">
                    <h2>Sai Guberan Pvt Ltd</h2>
                    <p>Organizing a software company demands expertise in turning challenges into opportunities. At Sai Guberan Pvt Ltd, we specialize in leveraging technology to drive business success. 
                        Our focus is on delivering innovative solutions that not only meet but exceed expectations, ensuring your growth and competitive edge</p>
                </div>
                    <div className="footer-section">
      <h2>Our Services</h2>
      <div className="service-icons">
        <div className="service-icon">
          <CloudOutlinedIcon className="icon" />
          <Link to="/Cloudcomputing">
            <span>Cloud Solutions</span>
          </Link>
        </div>
        <div className="service-icon">
          <DataUsageOutlinedIcon className="icon" />
          <Link to="/Dataengineer">
            <span>Data Engineering</span>
          </Link>
        </div>
        <div className="service-icon">
          <WebOutlinedIcon className="icon" />
          <Link to="/Webdevelopment">
            <span>Web Development</span>
          </Link>
        </div>
        <div className="service-icon">
          <PhoneAndroidOutlinedIcon className="icon" />
          <Link to="/Mobileapp">
            <span>Mobile App Designing</span>
          </Link>
        </div>
        <div className="service-icon">
          <BusinessCenterOutlinedIcon className="icon" />
          <Link to="/internship">
            <span>Internship Programs</span>
          </Link>
        </div>
        <div className="service-icon">
          <WorkOutlineOutlinedIcon className="icon" />
          <Link to="/it-staffing">
            <span>IT Staffing</span>
          </Link>
        </div>
      </div>
    </div>
                
                {/* Contact Details */}
                <div className="footer-section">
                    <h2>Contact Us</h2>
                    <p><strong>Phone:+1-646-864-9094</strong></p>
                    <p><strong>Email:info@globalsgtech.com</strong></p>
                    <p><strong>Address:</strong> 6952 San Pedro Cir, Buena Park, California-90620</p>
                    <div className="social-icons">
                        <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer