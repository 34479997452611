import React from "react";
import Header from "../Components/Header"
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import contimg1 from '../images/contimggg1.jpeg'
import contimg2 from '../images/contimg2.jpeg'
import contimg3 from '../images/contimg3.jpeg'
import contimg4 from '../images/contimg4.jpeg'
import contimg5 from '../images/contimg5.jpeg'
import contimg6 from '../images/contimg6.jpeg'
import serviceimg1 from '../images/serviceimg2.png';
import serviceimg2 from '../images/serviceimg3.jpeg'
import './Service.css'
import Footer from './Footer';
const WebDevelopment = () => {
    const navigate = useNavigate();

    const clickwebdevelopment = () => {
        navigate('/Webdevelopment');
    };
    const clickmobileapp = () => {
        navigate('/Mobileapp'); 
    };
    const clickclouddata = () => {
        navigate('/Cloudcomputing');
    };
    const clickdataengineer = () => {
        navigate('/Dataengineer');
    };
    const clickKidsprogram = () => {
        navigate('/Kidsprograme');
    };
    const Internshipprogram = () => {
        navigate('/internship');
    };

    return (
        <>
        <div>
            <Header/>
        </div>
        <div className="main-content">
                <div className="hero" style={{ backgroundImage: `url(${serviceimg2})` }}>
                    <div className="hero-content">
                    <h1 style={{fontSize:'80px',color:'white',fontWeight:'bold', marginTop:'revert'}}>Services</h1>
                    </div>
                </div>
            </div>
            <div className="content-animation1">
                <span className="text-animation1">We are designed to help you achieve your goals and grow your business</span>
            </div>
            <div className="imagegrid">
                {/* <div className="webimg1">
                    <img src={contimg1} alt="image" />
                    <p className="topic-title" onClick={clickwebdevelopment}>Web Development</p>
                </div> */}
                <div className="webimg1">
                <img src={contimg1} alt="image" />
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={clickwebdevelopment}
                    className="webdev-button"
                >
                    Web Development
                </Button>
            </div>
                <div className="webimg1">
                    <img src={contimg2} alt="image" />
                    <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={clickclouddata}
                    className="webdev-button"
                >
                    Cloud Computing
                </Button>
                    {/* <p className="topic-title" onClick={clickclouddata}>Cloud Computing</p> */}
                </div>
                <div className="webimg1">
                    <img src={contimg3} alt="image" />
                    <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={clickdataengineer}
                    className="webdev-button"
                >
                    Data Engineering
                </Button>
                    {/* <p className="topic-title" onClick={clickdataengineer}>Data Engineering</p> */}
                </div>
            </div>
            <div className="imagegrid">
                <div className="webimg1">
                    <img src={contimg4} alt="image" />
                    <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={clickmobileapp}
                    className="webdev-button"
                >
                    MobileApp Development
                </Button>
                    {/* <p className="topic-title" onClick={clickmobileapp}>MobileApp Development</p> */}
                </div>
                <div className="webimg1">
                    <img src={contimg5} alt="image" />
                    <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={Internshipprogram}
                    className="webdev-button"
                >
                    Internship Programming
                </Button>
                    {/* <p className="topic-title" onClick={Internshipprogram}>Internship Programming</p> */}
                </div>
                <div className="webimg1">
                    <img src={contimg6} alt="image" />
                    <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={clickKidsprogram}
                    className="webdev-button"
                >
                    online kids program
                </Button>
                    {/* <p className="topic-title" onClick={clickKidsprogram}>Kids Programming</p> */}
                </div>
            </div>
            <Footer/>
        </>
    );
};
export default WebDevelopment