// src/pages/KidsProgramming.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from './Footer';
import kidsProgrammingImg from '../images/kidsimg.jpeg'
import './Kidsprogram.css'

const KidsProgramming = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/Service');
    };

    return (
        <>
            <Header />
            <div className="kp-container">
                {/* Hero Section */}
                <section className="kp-hero-section">
                    <div className="kp-hero-content">
                        <h1 className="kp-hero-title">Kids Programming</h1>
                        <p className="kp-hero-description">
                            Ignite your child's passion for technology with our fun and interactive programming courses designed for young learners. Explore the world of coding in an engaging and educational way.
                        </p>
                        <button className="kp-hero-button" onClick={goBack}>Back to Service</button>
                    </div>
                    <img src={kidsProgrammingImg} alt="Kids Programming" className="kp-hero-image" />
                </section>

                {/* Features Section */}
                <section className="kp-features-section">
                    <h2 className="kp-features-title">Why Choose Our Kids Programming Courses?</h2>
                    <div className="kp-features-grid">
                        <div className="kp-feature-card">
                            <div className="kp-feature-icon">🎮</div>
                            <h3>Interactive Learning</h3>
                            <p>Engage kids with hands-on coding projects and interactive tools.</p>
                        </div>
                        <div className="kp-feature-card">
                            <div className="kp-feature-icon">🧩</div>
                            <h3>Fun Challenges</h3>
                            <p>Enhance problem-solving skills through creative and fun coding challenges.</p>
                        </div>
                        <div className="kp-feature-card">
                            <div className="kp-feature-icon">🌟</div>
                            <h3>Creative Expression</h3>
                            <p>Allow kids to create their own games, animations, and stories.</p>
                        </div>
                    </div>
                </section>

                {/* Curriculum Section */}
                <section className="kp-curriculum-section">
                    <h2 className="kp-curriculum-title">Our Exciting Curriculum</h2>
                    <div className="kp-curriculum-list">
                        <div className="kp-curriculum-item">
                            <h3>Introduction to Coding</h3>
                            <p>Learn the basics of programming and logic using simple coding languages.</p>
                        </div>
                        <div className="kp-curriculum-item">
                            <h3>Game Development</h3>
                            <p>Create your own games and understand the principles of game design.</p>
                        </div>
                        <div className="kp-curriculum-item">
                            <h3>Storytelling with Code</h3>
                            <p>Use code to tell interactive stories and develop creative digital projects.</p>
                        </div>
                    </div>
                </section>
               
                {/* Arts and Creativity Section */}
                <section className="kp-arts-section">
                    <h2 className="kp-arts-title">Explore Arts & Creativity</h2>
                    <div className="kp-arts-grid">
                        <div className="kp-arts-card">
                            <div className="kp-arts-icon">🎨</div>
                            <h3>Painting</h3>
                            <p>Encourage artistic skills by learning different painting techniques and creating colorful artworks.</p>
                        </div>
                        <div className="kp-arts-card">
                            <div className="kp-arts-icon">🖍️</div>
                            <h3>Drawing</h3>
                            <p>Develop drawing skills and creativity through structured exercises and free drawing sessions.</p>
                        </div>
                        <div className="kp-arts-card">
                            <div className="kp-arts-icon">🖌️</div>
                            <h3>Crafting</h3>
                            <p>Explore various crafting techniques to make unique and personalized art projects.</p>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    );
};

export default KidsProgramming;