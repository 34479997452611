import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from './Footer';
import cloudComputingImg from '../images/cloudcompute.jpeg'; // Import the image
import './Cloudcomputing.css'

const CloudComputing = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/Service');
    };

    return (
        <>
            <Header />
            <div className="cloudcomputing-container">
                {/* Hero Section */}
                <section className="cloudcomputing-hero">
                    <div className="hero-content">
                        <h1 className="hero-title">Empower Your Business with Cloud Computing</h1>
                        <p className="hero-description">
                            Unlock the potential of your business with our state-of-the-art cloud computing services. Our solutions ensure flexibility, security, and scalability tailored to your needs.
                        </p>
                        <button className="hero-button" onClick={goBack}>Back to Service</button>
                    </div>
                    <img src={cloudComputingImg} alt="Cloud Computing" className="hero-img" />
                </section>

                {/* Main Content */}
                <section className="cloudcomputing-main">
                    <div className="feature-block">
                        <h2 className="feature-title">Why Choose Our Cloud Solutions?</h2>
                        <div className="feature-grid">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <i className="fas fa-lock"></i>
                                </div>
                                <h3>Secure & Compliant</h3>
                                <p>Our cloud solutions are designed with the highest security standards and compliance to protect your data.</p>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <i className="fas fa-expand"></i>
                                </div>
                                <h3>Scalable Solutions</h3>
                                <p>Effortlessly scale your resources up or down based on your business needs without compromising performance.</p>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <i className="fas fa-cloud"></i>
                                </div>
                                <h3>High Availability</h3>
                                <p>Our cloud infrastructure ensures high availability and minimal downtime, keeping your operations running smoothly.</p>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <i className="fas fa-chart-line"></i>
                                </div>
                                <h3>Data Insights</h3>
                                <p>Gain actionable insights with advanced data analytics tools integrated into our cloud solutions.</p>
                            </div>
                        </div>
                    </div>

                    <div className="process">
                        <h2 className="process-title">Our Cloud Computing Process</h2>
                        <div className="process-steps">
                            <div className="process-step">
                                <div className="step-icon">
                                    <i className="fas fa-lightbulb"></i>
                                </div>
                                <div className="step-content">
                                    <h3>1. Consultation & Planning</h3>
                                    <p>We work with you to understand your requirements and design a tailored cloud strategy that fits your business goals.</p>
                                </div>
                            </div>
                            <div className="process-step">
                                <div className="step-icon">
                                    <i className="fas fa-cogs"></i>
                                </div>
                                <div className="step-content">
                                    <h3>2. Implementation</h3>
                                    <p>Our team sets up and configures the cloud infrastructure, ensuring everything is optimized and ready for your use.</p>
                                </div>
                            </div>
                            <div className="process-step">
                                <div className="step-icon">
                                    <i className="fas fa-rocket"></i>
                                </div>
                                <div className="step-content">
                                    <h3>3. Testing & Optimization</h3>
                                    <p>We rigorously test your cloud environment and make adjustments to ensure peak performance and reliability.</p>
                                </div>
                            </div>
                            <div className="process-step">
                                <div className="step-icon">
                                    <i className="fas fa-check-circle"></i>
                                </div>
                                <div className="step-content">
                                    <h3>4. Support & Maintenance</h3>
                                    <p>We provide ongoing support and maintenance to ensure your cloud infrastructure remains secure and efficient.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cloud-benefits">
                        <h2 className="benefits-title">Cloud Computing Benefits</h2>
                        <div className="benefits-list">
                            <div className="benefit-item">
                                <h3>Cost Efficiency</h3>
                                <p>Reduce your IT costs with our pay-as-you-go cloud solutions, only paying for what you use.</p>
                            </div>
                            <div className="benefit-item">
                                <h3>Flexibility</h3>
                                <p>Access your resources from anywhere, anytime, and scale them according to your business needs.</p>
                            </div>
                            <div className="benefit-item">
                                <h3>Enhanced Collaboration</h3>
                                <p>Improve team collaboration with cloud-based tools that facilitate real-time access and sharing of information.</p>
                            </div>
                            <div className="benefit-item">
                                <h3>Disaster Recovery</h3>
                                <p>Ensure business continuity with robust disaster recovery solutions integrated into our cloud services.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    );
};

export default CloudComputing;